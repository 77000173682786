import  { useEffect } from "react";
import { useConfigurationStore } from "store/ConfigurationStore";
import { useAuthStore } from "store/AuthStore";
import { useGlobalStore } from "store/GlobalStore";
import { getBasketCount } from "services/allServices";
import Header from "../Header";
import Footer from "../Footer";
import TopHeader from "../TopHeader";

const layoutStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%"
};

const contentStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column"
};

function Layout(props) {
  const [checkLocalStorage, setBasketCount] = useConfigurationStore((state) => [state.checkLocalStorage, state.setBasketCount]);
  const [checkLocalStorageAuth, isLoggin, setIsLoggin] = useAuthStore((state) => [state.checkLocalStorageAuth, state.isLoggin, state.setIsLoggin]);
  const [checkLocalStorageGlobal, setCurrencyRates] = useGlobalStore((state) => [state.checkLocalStorageGlobal, state.setCurrencyRates]);

  useEffect(() => {
    checkLocalStorageAuth();
    checkLocalStorage();
    checkLocalStorageGlobal();
    // getCurrencyRates().then(data => {
    //   setCurrencyRates(data);
    // }).catch(err => {
    //   console.log(err);
    //   setIsLoggin(false);
    // });
    if (isLoggin) {
      getBasketCount().then(data => {
        setBasketCount(data.count);
      }).catch(err => {
          setBasketCount(0);
          if (err?.request?.status === 401) {
            setIsLoggin(false);
          }
        }
      );

    } else {
      setBasketCount(0);
    }
  }, []);

  return (
    <div className="Layout" style={layoutStyle}>
      <TopHeader />
      <Header />
      <div className="Content" style={contentStyle}>
        {props.children}
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
